import React, { useRef } from 'react'
import TextField from '@mui/material/TextField'
import Autocomplete from '@mui/material/Autocomplete'
import CircularProgress from '@mui/material/CircularProgress'
import { Traduccion } from './Traduccion'

export const AutocompleteComp = ({ id, onSelect, clearOnSelect, autoFocus, seleccion, label, itemLabel, serviceCall, responseData, editable = true, requerido, validarSubmit }) => {
    const [open, setOpen] = React.useState(false)
    const [options, setOptions] = React.useState([])
    const [loading, setLoading] = React.useState(false)
    const [inputValue, setInputValue] = React.useState(itemLabel(seleccion))
    const timeoutRef = useRef(null)

    const _handleSelect = (event, val) => {
        if (!val?.id) return
        setOpen(false)
        onSelect(val)
        if (clearOnSelect) {
            setInputValue('')
        }
    }

    const _handleInputChange = (event, val) => {
        setInputValue(val)
        if (timeoutRef.current !== null) {          // IF THERE'S A RUNNING TIMEOUT
            clearTimeout(timeoutRef.current)       // THEN, CANCEL IT
        }
        if (val.length > 2 && event && event.type !== 'click' && event.key !== 'Enter') {
            timeoutRef.current = setTimeout(() => {
                timeoutRef.current = null          // RESET REF TO NULL WHEN IT RUNS
                setLoading(true)
                serviceCall(val)
                    .then(data => {
                        setOptions(responseData ? data[responseData] : data)
                        setOpen(true)
                        setLoading(false)
                    })
            }, 750)
        }
    }

    const _getOptionLabel = (option) => option.id ? itemLabel(option) : option

    React.useEffect(() => {
        setInputValue(itemLabel(seleccion))
    }, [seleccion, itemLabel])

    return (
        <Autocomplete id={id}
            clearText={Traduccion('TXT_BORRAR')}
            open={open}
            options={options}
            getOptionLabel={_getOptionLabel}
            value={seleccion}
            onChange={_handleSelect}
            inputValue={inputValue}
            onInputChange={_handleInputChange}
            disabled={!editable}
            freeSolo
            renderInput={(params) => (
                <TextField label={Traduccion(label)}
                    variant="outlined"
                    margin="dense"
                    autoFocus={autoFocus}
                    required={requerido}
                    helperText={validarSubmit && requerido && !seleccion ? Traduccion('TXT_REQUERIDO') : ''}
                    error={validarSubmit && requerido && !seleccion}
                    {...params}
                    InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                            <React.Fragment>
                                {loading && <CircularProgress color="inherit" size={20} />}
                                {params.InputProps.endAdornment}
                            </React.Fragment>
                        )
                    }} />
            )}
        />
    )
}